<template>
  <div class="live">
    <left-tabs :tabList="leftList" />
     <router-view></router-view>
  </div>
</template>
<script>
import ContentTitle from "@/components/contentTitle";
import LeftTabs from "@/components/leftTabs";
export default {
  name: "live",
  components: {
    ContentTitle,
    LeftTabs,
  },
  data() {
    return {
        leftList: [
          {id: 1, name: '直播间', url: '/live/room'},
          {id: 2, name: '直播分类', url: '/live/cate'},
		      {id: 3, name: '直播记录', url: '/live/live_list'},
          {id: 4, name: '抽奖设置', url: '/live/drawSet'},
          {id: 5, name: '中奖人员', url: '/live/winningPersonnel'},
          {id: 6, name: '福袋管理', url: '/live/luckyBag'},
          {id: 6, name: '福袋中奖人员', url: '/live/winnerList'},
        ],
    };
  },
  methods: {

  },

};
</script>
<style scoped lang="less">
.live {
  display: flex;
}

</style>
