<template>
  <div class="contentTitle">
    <p>当前位置: <span><slot /></span></p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="less">
.contentTitle {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #333;
  height: 42px;
  border-left: 5px solid #009688;
  padding-left: 10px;
  p {
    span {
      color: #009688;
      margin-left: 5px;
    }
  }
}
</style>